import axios from "axios";

export const isDev = () =>   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const uri = isDev()? 'https://v1.claima.io' : 'https://v1.claima.io';
export const uri_img = 'https://v1.claima.io';
// export const uri = isDev()? 'https://v2.amanmedicare.com/' : 'https://v2.amanmedicare.com/';
// export const uri_img = 'https://v2.amanmedicare.com/';
// export const worldTimeApi = 'http://worldtimeapi.org/api/ip';

export const headers =  {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Pragma': 'no-cache',
  'Expires': 0,
  'Version': '1.5.1',
  'Application': 2,
  'Dev':  isDev()? 1 : 0
}

  
const Axios = axios.create({baseURL: uri, headers });

export default Axios;